<template>
  <div class="add-auth">
    <div class="left">
      <div class="left-top">
        <a-icon type="cloud-upload" style="margin-right:10px;color:#1890ff" />接口列表
      </div>
      <div class="fenge" />
      <div class="left-content">
        <a-spin :spinning="loading.api">
          <a-tree
            v-if="apiList.length > 0"
            v-model="apiCheckedKey"
            checkable
            :auto-expand-parent="true"
            :default-expand-all="true"
            :tree-data="apiList"
            :replace-fields="{children:'children', title:'name', key:'key'}"
          />
        </a-spin>
      </div>
      <div class="left-bottom">
        <a-button :loading="loading.editApi" type="primary" block ghost @click="handleSaveApi">保存</a-button>
      </div>
    </div>
    <div class="right">
      <div class="right-top">
        <a-icon type="menu-unfold" style="margin-right:10px;color:#1890ff" />菜单列表
      </div>
      <div class="fenge" />
      <div class="right-content">
        <a-spin :spinning="loading.menu">
          <a-tree
            v-if="menuList.length > 0"
            v-model="menuCheckedKey"
            checkable
            :check-strictly="true"
            :auto-expand-parent="true"
            :default-expand-all="true"
            :tree-data="menuList"
            :replace-fields="{children:'children', title:'name', key:'id'}"
          />
        </a-spin>
      </div>
      <div class="right-bottom">
        <a-button :loading="loading.editMenu" type="primary" block ghost @click="handleSaveMenu">保存</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import adminApi from '../../../api/admin'
import myapi from '../../../api/api'
import menuApi from '../../../api/menu'
export default {
  data() {
    return {
      apiList: [],
      menuList: [],
      apiCheckedKey: [],
      menuCheckedKey: { checked: [] },
      loading: {
        api: false,
        menu: false,
        editApi: false,
        editMenu: false
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading.api = true
      this.loading.menu = true
      const myapireq = this.fetchApiData()
      const menureq = this.fetchMenuData()
      Promise.all([myapireq, menureq]).then(_ => {
        this.reflushChecked()
      })
    },
    reflushChecked() {
      adminApi.getRoleById(this.$route.query.id).then(res => {
        this.apiCheckedKey = res.data.api.split(',').map(item => Number(item))
        this.menuCheckedKey.checked = res.data.menu.split(',').map(item => Number(item))
      })
    },
    fetchApiData() {
      this.loading.api = true
      const myapireq = myapi.groupDetail().then(res => {
        this.apiList = res.data.map(item => {
          item.checkable = false
          item.children.forEach(child => {
            child.key = Number(child.id)
          })
          return item
        })
      }).finally(_ => { this.loading.api = false })
      return myapireq
    },
    fetchMenuData() {
      this.loading.menu = true
      const menureq = menuApi.list().then(res => {
        this.menuList = res.data
      }).finally(_ => { this.loading.menu = false })
      return menureq
    },
    handleSaveApi() {
      this.loading.editApi = true
      adminApi.editRole({ id: this.$route.query.id, api: this.apiCheckedKey.join(',') }).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
          this.fetchApiData()
          this.reflushChecked()
        } else {
          this.$message.error(res.msg)
        }
      }).finally(_ => { this.loading.editApi = false })
    },
    handleSaveMenu() {
      const checked = []
      this.menuCheckedKey.checked.forEach((item) => {
        if (item !== 0) {
          checked.push(item)
        }
      })
      this.loading.editMenu = true
      adminApi.editRole({ id: this.$route.query.id, menu: checked.join(',') }).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
          this.fetchMenuData()
          this.reflushChecked()
        } else {
          this.$message.error(res.msg)
        }
      }).finally(_ => { this.loading.editMenu = false })
    }
  }
}
</script>
<style scoped>
.add-auth{
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.left {
  padding: 10px;
  width: 49%;
  background-color: #fafafa;
  box-shadow: 0 20px 27px rgba(0,0,0,.2);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
}
.left-top {
  font-size: 24px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.left-content {
  margin-left: 40px;
  overflow-y: auto;
  height: calc(100% - 90px);
  padding-bottom: 20px;
}
.left-bottom {
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}
.right {
  padding: 10px;
   box-sizing: border-box;
  width: 49%;
  background-color: #fafafa;
  box-shadow: 0 20px 27px rgba(0,0,0,.2);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.right-top {
  font-size: 24px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.right-content {
  margin-left: 40px;
  overflow-y: auto;
  height: calc(100% - 90px);
  padding-bottom: 20px;
}
.right-bottom {
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}
.fenge {
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #e4e4e4;
}
.left-content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.left-content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  background   : #40a9ff;
}
.left-content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
.right-content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.right-content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  background   : #40a9ff;
}
.right-content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
</style>
