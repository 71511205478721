import request from '@/utils/request'

export function list(params) {
  return request({
    url: '/admin/api/list',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: '/admin/api',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/api',
    method: 'put',
    data
  })
}

export function del(id) {
  return request({
    url: `/admin/api/${id}`,
    method: 'delete'
  })
}

export function groupList(params) {
  return request({
    url: '/admin/api-group/list',
    method: 'get',
    params
  })
}

export function groupAdd(data) {
  return request({
    url: '/admin/api-group',
    method: 'post',
    data
  })
}

export function groupEdit(data) {
  return request({
    url: '/admin/api-group',
    method: 'put',
    data
  })
}

export function groupDel(id) {
  return request({
    url: `/admin/api-group/${id}`,
    method: 'delete'
  })
}

export function groupDetail() {
  return request({
    url: `/admin/api-group/list-detail`,
    methods: 'get'
  })
}

const apiApi = {
  list,
  add,
  edit,
  del,
  groupList,
  groupDetail,
  groupAdd,
  groupDel,
  groupEdit
}

export default apiApi
